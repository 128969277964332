
//inject popup with default value, set initial position, width is not reflected on this.
$(".range-slider input[type=range]").on('touchstart', function(e) {
  var container = $(this)
  ,svg = '<svg class="icon-m hide-on-fallback" role="img" title="accarrowdn-glyph"><use xlink:href="dist/img/icons/icons.svg#accarrowdn-glyph"></use><image class="icon-fallback" src="dist/img/icons/accarrowdn-glyph.png" xlink:href=""></image></svg>'
  ,x = e.originalEvent.touches[0].pageX
  ,bubble = $('<div />', { 'class' : 'bubble' })
      .append($('<div />', { 'class': 'value', 'text' : $(this).val()}))
      .css({left: x})
      .append(svg)
      .insertAfter(container);
});
//update left position offset
$(".range-slider input[type=range]").on('touchmove', function(e) {
  var value= $(this).val()
  ,bubble = $(this).parent().find('.bubble')
  ,x = e.originalEvent.touches[0].pageX;

  bubble.css({left: x - (bubble.outerWidth()/2)});
  bubble.find('.value').text(value);

});
//remove range popup
$(".range-slider input[type=range]").on('touchend', function() {
  $(this).parent().find('.bubble').remove()
});

