+function ($) { "use strict";

  // INLINE SEARCHBAR CLASS DEFINITION
  // =========================

	var InlineSearchbar = function(element, options) {
    this.$element = $(element);
    this.options  = options;

    this.$searchInput = $(element).find('input');
    this.$searchSuggestList = $(element).find('[data-id="search-suggest"]');
    this.$searchSuggestListItems = $(element).find('[data-id="search-suggest"] li');

    // listeners
    this.$element.on('keyup.aia.inlinesearchbar', this.keyup.bind(this));
    this.$searchInput.on('keyup.aia.inlinesearchbar', this.didKeySuggestion.bind(this));
    this.$searchInput.on('focusout', this.leave.bind(this));
	};

  InlineSearchbar.VERSION = '1.0';
  InlineSearchbar.DEFAULTS = {};

  InlineSearchbar.prototype.leave = function(e) {
    this.$element.removeClass('open');
    this.$searchSuggestList.hide();
  }

  InlineSearchbar.prototype.keyup = function(e) {
    // 38 up
    // 40 down
    // 27 escape
    // 32 space
    if (!/(38|40|27|32)/.test(e.which)) return;
    var isActive = this.$element.hasClass('open');
    if (!isActive) return;
    if (!this.$searchSuggestListItems.length) return;

    e.preventDefault();
    e.stopPropagation();

    var index = 0;
    if (e.which == 27) {
      this.$element.removeClass('open');
      this.$searchSuggestList.hide();
    }
    // if (this.$searchInput.is(':focus')) {
    //   // debugger;
    //   if (e.which == 38) { // up to last item
    //     index = this.$searchSuggestListItems.length - 1;
    //   }
    //   if (e.which == 40) { // down to first item
    //     index = 0;
    //   }
    // } else { // suggest list is in focus
    //   var index = this.$searchSuggestListItems.index(e.target);
    //   if (e.which == 38 && index > 0) { // up
    //     index--;
    //   }
    //   if (e.which == 40 && this.$searchSuggestListItems.length - 1) { // down to first item
    //     index++;
    //   }
    // }
    // this.$searchSuggestListItems.eq(index).trigger('focus');
  }

  InlineSearchbar.prototype.didKeySuggestion = function(e) {
    // ignore accesibility keys
    if (/(38|40|27)/.test(e.which)) return;

    var val = this.$searchInput.val();
    if (val.length > 4) {
      this.$element.addClass('open');
      this.$searchSuggestList.show();
    } else {
      this.$element.removeClass('open');
      this.$searchSuggestList.hide();
    }
  }

  // PLUGIN DEFINITION
  // =====================

  function Plugin(option) {
    return this.each(function () {
      var $this = $(this);
      var data  = $this.data('aia.inlinesearchbar');

      if (!data) $this.data('aia.inlinesearchbar', (data = new InlineSearchbar(this)));
      if (typeof option == 'string') data[option](); // option is method call
    })
  }

  var old = $.fn.inlinesearchbar;

  $.fn.inlinesearchbar             = Plugin;
  $.fn.inlinesearchbar.Constructor = InlineSearchbar;


  // NO CONFLICT
  // ===============

  $.fn.inlinesearchbar.noConflict = function () {
    $.fn.inlinesearchbar = old;
    return this;
  };
}(jQuery);
