+function ($) { "use strict";
  var Navbar = function(navMenuElement, navBarElement) {
    // caching elements
    this.$window = $(window);
    this.$body = $('body');
    this.$navMenu = $(navMenuElement);
    this.$navBar = $(navBarElement);
    this.$navBarZero = this.$navBar.find('.navbar-zero');
    this.$slimBar = this.$navBar.find('.navbar-slim');
    this.$navDropdowns = this.$navBar.find('.navbar-nav li');
    this.$navDropdownDismissButton = this.$navBar.find('[data-dismiss="navbar-dropdown"]');
    this.$navMenuProfile = this.$navMenu.find('#navmenu-profile');
    this.$navMenuLogin = this.$navMenu.find('#navmenu-login');
    this.$searchInput = this.$navBar.find('[data-id="search-input"]');
    this.$searchResults = this.$navBar.find('.search-suggest-box');
    this.$searchButton = this.$navBar.find('.navbar-search-btn');
    this.$searchDismissButton = this.$navBar.find('.dismiss-search-btn'); // [data-dismiss="navbar-search"]
    this.$searchDropdown = this.$navBar.find('.navbar-search-btn > .dropdown-toggle');
    this.authorizationId = this.getUrlParam('authorizationId'); // for future use
    this.screenSmMin = '768px';
    this.screenSdMin = '1025px';
    this.screenSmallMediaQuery = window.matchMedia('(min-width: ' + this.screenSmMin + ')');
    this.screenShmediumMediaQuery = window.matchMedia('(min-width: ' + this.screenSdMin + ')');
    this.navBarHeight = null;
    this.slimBarHeight = null;
    this.slimY = null;
    this.dy = null;
    this.dyPrev = 0;
    this.navBarOffsetClass = 'navbar-offset-xs';
    this.didScroll = false;
    this.delta = 5;
    this.scrollDetectRefreshInterval = 250;

    // initialization
    this.setElementSizes();

    // listeners
    this.$window.on('scroll.aia.navbar', function(e) { this.didScroll = true; }.bind(this));
    this.screenSmallMediaQuery.addListener(this.setElementSizes.bind(this));
    this.screenShmediumMediaQuery.addListener(this.setElementSizes.bind(this));
    this.$navDropdownDismissButton.on('click.aia.navbar', this.dismissNavDropdown.bind(this));
    this.$searchInput.on('keyup.aia.navbar', this.searchInputKeyed.bind(this));
    this.$searchButton.on('hidden.bs.dropdown', this.hideSearchResults.bind(this));
    this.$searchButton.on('shown.bs.dropdown', function(e) { this.$searchInput.focus(); }.bind(this));
    this.$searchDismissButton.on('click.aia.navbar', this.dismissSearchDropdown.bind(this));

    // scroll detection timer
    this.checkScrollInterval = setInterval(function() {
      if (this.didScroll) {
        this.hasScrolled();
        this.didScroll = false;
      }
    }.bind(this), this.scrollDetectRefreshInterval);
  }

  Navbar.prototype.dismissNavDropdown = function() {
    this.$navDropdowns.removeClass('open');
  }

  Navbar.prototype.searchInputKeyed = function() {
    var val = this.$searchInput.val();
    if (val.length > 3) {
      this.$searchResults.show();
    } else {
      this.$searchResults.hide();
    }
    if (this.navBarOffsetClass === 'navbar-offset-xs') {
      var height = this.$window.height() - this.navBarHeight - 60; // search bar height;
      if (height > this.$searchResults.height()) {
        this.$searchResults.height(height);
      }
    }
  }

  Navbar.prototype.hideSearchResults = function() {
    this.$searchResults.hide();
    this.$searchInput.val('');
    this.$searchInput.blur();
  }

  Navbar.prototype.dismissSearchDropdown = function(e) {
    this.$searchDropdown.dropdown('toggle');
    e.preventDefault();
  }

  /**
   *  Sticky header functions
   */
  Navbar.prototype.setElementSizes = function() {
    this.navBarHeight = this.$navBar.height();
    this.slimBarHeight = this.$slimBar.height();
    this.slimY = this.navBarHeight - this.slimBarHeight;

    if (this.screenShmediumMediaQuery.matches) {
      if (this.$navBarZero.length && this.$navBarZero.is(':visible')) {
        this.navBarOffsetClass = 'navbar-offset-sd-zero';
      } else {
        this.navBarOffsetClass = 'navbar-offset-sd';
      }
    } else if (this.screenSmallMediaQuery.matches) {
      this.navBarOffsetClass = 'navbar-offset-sm';
    } else {
      this.navBarOffsetClass = 'navbar-offset-xs';
    }
    // if in slim state,
    if ((this.$navBar).hasClass('navbar-fixed-slim')) {
      this.$navBar.removeClass('navbar-offset-xs navbar-offset-sm navbar-offset-sd navbar-offset-sd-zero');
      this.$navBar.addClass(this.navBarOffsetClass);
    }
  }

  Navbar.prototype.hoverExpandNavBar = function() {
    if (this.screenShmediumMediaQuery.matches) {
      this.$navBar.removeClass('navbar-fixed-slim');
      this.$navBar.removeClass('navbar-offset-xs navbar-offset-sm navbar-offset-sd navbar-offset-sd-zero');
      this.$navBar.on('mouseleave.aia.navbar', this.hoverShrinkNavbar.bind(this));
    }
  }

  Navbar.prototype.hoverShrinkNavbar = function() {
    this.$navBar.addClass('navbar-fixed-slim').addClass(this.navBarOffsetClass);
    this.$navBar.off('mouseleave.aia.navbar');
  }

  Navbar.prototype.hasScrolled = function() {
    this.dy = this.$window.scrollTop();
    if (Math.abs(this.dyPrev - this.dy) <= this.delta)
      return;

    if (this.dy > this.dyPrev && this.dy > this.slimY) { // scroll down
      // affix slim nav bar
      this.$navBar.addClass('navbar-fixed-slim').addClass(this.navBarOffsetClass);
      this.$slimBar.on('mouseenter.aia.navbar', this.hoverExpandNavBar.bind(this));
    } else { // scroll up
      if ('ontouchstart' in document.documentElement) { // for touch devices
        // affix fat bar
        this.$navBar.removeClass('navbar-fixed-slim');
        this.$navBar.removeClass('navbar-offset-xs navbar-offset-sm navbar-offset-sd navbar-offset-sd-zero');
        this.$slimBar.off('mouseenter.aia.navbar');
      } else if (this.dy < this.slimY) { // 768 and above
        // slim bar to fat bar
        this.$navBar.removeClass('navbar-fixed-slim');
        this.$navBar.removeClass('navbar-offset-xs navbar-offset-sm navbar-offset-sd navbar-offset-sd-zero');
        this.$slimBar.off('mouseenter.aia.navbar');
      } // else keep slim bar
    }
    this.dyPrev = this.dy;
  }


  /**
   *  Pre/Post login functions
   */
  Navbar.prototype.isLoggedIn = function() {
    var isLoggedIn = typeof this.authorizationId !== 'undefined' &&
      this.authorizationId.length > 0 &&
      this.authorizationId !== 'anonymous';
    return isLoggedIn;
  }

  Navbar.prototype.getUrlParam = function(param) {
    var pageUrl = window.location.search.substring(1);
    var urlVariables = pageUrl.split('&');
    for (var i = 0; i < urlVariables.length; i++)
    {
      var paramName = urlVariables[i].split('=');
      if (paramName[0] == param)
      {
          return paramName[1];
      }
    }
  }

  // PLUGIN DEFINITION
  // =====================

  function Plugin(option) {
    return this.each(function () {
      var $this = $(this);
      var data  = $this.data('aia.navbar');
      var options = typeof option == 'object' && option;

      if (!data) $this.data('aia.navbar', (data = new Navbar(this, options)));
      if (typeof option == 'string') data[option].call($this);
    });
  }

  var old = $.fn.navbar;

  $.fn.navbar             = Plugin;
  $.fn.navbar.Constructor = Navbar;


  // NO CONFLICT
  // ===============

  $.fn.navbar.noConflict = function () {
    $.fn.navbar = old;
    return this;
  };


  // DATA-API
  // ============

  var loadNavbarHandler = function() {
    // expect only one instance of navbar
    var $navmenu = $('.navmenu');
    var $navbar = $('.navbar');
    Plugin.call($navmenu, $navbar);
  };

  $(window)
    .on('load.aia.navbar', loadNavbarHandler);
}(jQuery);
