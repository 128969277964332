$(document).ready(function() {
	resizeCards();
	var screenSmMin = '768px';
  var screenSmallMediaQuery = window.matchMedia('(min-width: ' + screenSmMin + ')');

	screenSmallMediaQuery.addListener(screenSmResize);
	// And share the same code to set things up with our current state.
	screenSmResize(screenSmallMediaQuery);

	function screenSmResize(screenSmallMediaQuery) {
	  if (screenSmallMediaQuery.matches) {
	  	resizeCards();
	  }
	  else {
	  	var cards = $('[data-id="cards"]').find('.card-1, .card-2, .card-3, .card-4, .card-6, .card-9, .card-10, .card-11, .card-12, .card-banner-desc');
			var cardBannerDesc = $('[data-id="cards"]').find('.card-banner-desc');

	  	// resetting card height
			cards.each(function(i,e) {
		  	$(e).css("height", "auto");
			});

			cardBannerDesc.each(function(i,e) {
		  	$(e).css("height", "auto");
			});
	  }
	}

	function resizeCards() {

		var maxHeight = 0;
		var cards = $('[data-id="cards"]').find('.card-1, .card-2, .card-3, .card-4, .card-6, .card-9, .card-10, .card-11, .card-12');
		var maxCardBannerDescHeight = 0;
		var cardBannerDesc = $('[data-id="cards"]').find('.card-banner-desc');

		// console.log(window.outerWidth);

		cards.each(function(i,e) {
		  if ($(e).height() > maxHeight) {
		  	maxHeight = $(e).height();
		  };
		});

		cards.each(function(i,e) {
		  $(e).height(maxHeight);
		});

		cardBannerDesc.each(function(i,e) {
		  if ($(e).height() > maxCardBannerDescHeight) {
		  	maxCardBannerDescHeight = $(e).height();
		  };
		});
		cardBannerDesc.each(function(i,e) {
		  $(e).height(maxCardBannerDescHeight);
		});
	}
});
