+function ($) {
  'use strict';

  // LIST 2 CLASS DEFINITION
  // ======================
  var List2   = function (el) {
    $(el).on('click', '[data-dismiss="list-2"]', this.close)
  }

  List2.VERSION = '1.0';

  List2.prototype.close = function (e) {
    var $this    = $(this);

    if (e) e.preventDefault();

    var $parent = $this.closest('li');

    $parent.trigger(e = $.Event('close.aia.list-2'));

    if (e.isDefaultPrevented()) return;

    $parent.removeClass('in');

    function removeElement() {
      // detach from parent, fire event then clean up data
      $parent.detach().remove();
    }

    $.support.transition && $parent.hasClass('fade') ?
      $parent
        .one('bsTransitionEnd', removeElement)
        .emulateTransitionEnd(List2.TRANSITION_DURATION) :
      removeElement()
  }


  // List 2 PLUGIN DEFINITION
  // =======================

  function Plugin(option) {
    return this.each(function () {
      var $this = $(this);
      var data  = $this.data('bs.list-2');

      if (!data) $this.data('bs.list-2', (data = new List2(this)));
      if (typeof option == 'string') data[option].call($this);
    })
  }

  var old = $.fn.alert

  $.fn.alert             = Plugin;
  $.fn.alert.Constructor = List2;


  // LIST 2 NO CONFLICT
  // =================

  $.fn.alert.noConflict = function () {
    $.fn.alert = old;
    return this;
  }


  // LIST 2 DATA-API
  // ==============

  $('.list-2 > li').on('click.bs.list-2.data-api', '[data-dismiss="list-2"]', List2.prototype.close);
}(jQuery);
